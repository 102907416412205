import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/SEO"
import Navbar from "../components/Navbar"

import useTranslation from "../translations/translate"

const About = ({ pageContext: { pageName } }) => {
  const { t } = useTranslation()

  const timelineEntries = t("page.aboutUs.timeline.entries").map((e, i) => (
    <div key={i} className="entry">
      <div className="title">
        <h3>{e.date}</h3>
        <p>{e.title}</p>
      </div>
      <div className="body">
        <p>{e.heading}</p>
        <p className="lead text-muted">{e.body}</p>
      </div>
    </div>
  ))

  return (
    <>
      <SEO
        title={t("page.aboutUs.title")}
        description={t("page.aboutUs.description")}
        pageName={pageName}
      >
        <h1 className="d-none">{t("page.aboutUs.title")}</h1>
      </SEO>

      <section className="position-relative vh-80 p-0 border-bottom">
        <Navbar
          color="dark"
          className="position-absolute top-0 left-0 w-100 z-100"
        />

        <StaticImage
          src="../components/assets/media/images/team.jpg"
          layout="fullWidth"
          quality="90"
          breakpoints={[750, 1080, 1366, 1920, 2560]}
          className="h-100 object-position-center"
          alt="Team EVOBEND"
          transformOptions={{
            fit: "cover",
          }}
          imgStyle={{
            objectPosition: "50% 20%",
          }}
        />
      </section>

      <section className="container">
        <p className="lead text-center display-6 mb-12">
          {t("page.aboutUs.statement")}
        </p>

        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="fw-bold text-center text-md-end mb-5 mb-md-0">
              {t("page.aboutUs.mission.title")}
            </h2>
          </div>

          <div className="col-12 col-md-8 mb-8">
            <p className="lead">{t("page.aboutUs.mission.body1")}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="fw-bold text-center text-md-end mb-5 mb-md-0">
              {t("page.aboutUs.company.title")}
            </h2>
          </div>

          <div className="col-12 col-md-8">
            <p className="lead">{t("page.aboutUs.company.body1")}</p>

            <p className="lead">{t("page.aboutUs.company.body2")}</p>
          </div>
        </div>
      </section>

      <section className="bg-light border-top border-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 mb-5 mb-md-0">
              <StaticImage
                src="../components/assets/media/images/emanuel.jpg"
                alt="Emanuel Allerberger"
                layout="fullWidth"
                className="rounded shadow"
              />
              <p className="h2 mt-5 mb-0">Emanuel Allerberger</p>
              <p className="quiet text-muted mb-0">
                {t("common.founderAndCEO")}
              </p>
            </div>

            <div className="col-12 col-md-4">
              <StaticImage
                src="../components/assets/media/images/reinhard.jpg"
                alt="Reinhard Franze"
                layout="fullWidth"
                className="rounded shadow"
              />
              <p className="h2 mt-5 mb-0">Reinhard Franze</p>
              <p className="quiet text-muted mb-0">{t("common.seniorBoss")}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container content d-flex p-0">
        <div className="timeline py-14">{timelineEntries}</div>
      </section>
    </>
  )
}

export default About
